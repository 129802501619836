import React, { Component, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { CookiesProvider, Cookies } from 'react-cookie';
import createBrowserHistory from 'history/createBrowserHistory';
import { FloatButton, Space, Typography, Divider } from 'antd';
import { UpOutlined } from '@ant-design/icons';
const { Text } = Typography;
const Home = lazy(() => import("./containers/Home"));
const Orders = lazy(() => import("./containers/Orders"));
const Order = lazy(() => import("./containers/Order"));
const OrderCreatePage = lazy(() => import("./containers/Order/OrderCreatePage"));
const Storage = lazy(() => import("./containers/Storage"));
const Inventory = lazy(() => import("./containers/Inventory"));
const StorageOrder = lazy(() => import("./containers/Storage/StorageOrder"));
const Delivery = lazy(() => import("./containers/Delivery"));
const StorageOverview = lazy(() => import("./containers/StorageOverview"));
const Brands = lazy(() => import("./containers/Brands"));
const Brand = lazy(() => import("./containers/Brand"));
const BrandCreatePage = lazy(() => import("./containers/Brand/BrandCreatePage"));
const Resources = lazy(() => import("./containers/Resources"));
const Resource = lazy(() => import("./containers/Resource"));
const ResourceCreatePage = lazy(() => import("./containers/Resource/ResourceCreatePage"));
const Category = lazy(() => import("./containers/Category"));
const CategoryCreatePage = lazy(() => import("./containers/Category/CategoryCreatePage"));
const Categories = lazy(() => import("./containers/Category/CategoriesList"));
const Clients = lazy(() => import("./containers/Clients"));
const Client = lazy(() => import("./containers/Client"));
const ClientCreatePage = lazy(() => import("./containers/Client/ClientCreatePage"));
const Invoices = lazy(() => import("./containers/Invoices"));
const Invoice = lazy(() => import("./containers/Invoice"));
const InvoiceCreatePage = lazy(() => import("./containers/Invoice/InvoiceCreatePage"));
const Contracts = lazy(() => import("./containers/Contracts"));
const Contract = lazy(() => import("./containers/Contract"));
const ContractCreatePage = lazy(() => import("./containers/Contract/ContractCreatePage"));
const ContractAnalysisPage = lazy(() => import("./containers/Contract/ContractAnalysisPage"));
const Logout = lazy(() => import("./containers/Logout"));
const Login = lazy(() => import("./containers/Login"));
const Inquiry = lazy(() => import("./containers/Inquiry"));
const Inquiries = lazy(() => import("./containers/Inquiries"));
const Members = lazy(() => import("./containers/Members"));
const User = lazy(() => import("./containers/User"));
const Payments = lazy(() => import("./containers/Payments"));
const PaymentProformas = lazy(() => import("./containers/paymentProformas"));
const PaymentCreate = lazy(() => import("./containers/Payment/create"));
const Payment = lazy(() => import("./containers/Payment/index"));
const BlockUsers = lazy(() => import("./containers/BlockUsers/index"));
const Permissions = lazy(() => import("./containers/Permissions/index"));
const Ztns = lazy(() => import("./containers/Ztns"));
const ZtnCreate = lazy(() => import("./containers/Ztn/create"));
const Ztn = lazy(() => import("./containers/Ztn/index"));
const Waybills = lazy(() => import("./containers/Waybills"));
const WaybillCreate = lazy(() => import("./containers/Waybill/create"));
const Waybill = lazy(() => import("./containers/Waybill/index"));
const Hscodes = lazy(() => import("./containers/Hscodes"));
const HscodeCreate = lazy(() => import("./containers/Hscode/create"));
const Hscode = lazy(() => import("./containers/Hscode/index"));
const WaybillStickerCreate = lazy(() => import("./containers/Sticker/create"));
const Incomes = lazy(() => import("./containers/Incomes"));
const IncomeCreate = lazy(() => import("./containers/Income/create"));
const Income = lazy(() => import("./containers/Income/index"));
const Todolist = lazy(() => import("./containers/Todolist"));
const MessageBar = lazy(() => import("./components/MessageBar"));
const Freights = lazy(() => import("./containers/Freights/index"));
const FreightCreate = lazy(() => import("./containers/Freight/create"));
const Freight = lazy(() => import("./containers/Freight/index"));
const Transfers = lazy(() => import("./containers/Transfers/index"));
const TransferCreate = lazy(() => import("./containers/Transfer/create"));
const Transfer = lazy(() => import("./containers/Transfer/index"));
const Buys = lazy(() => import("./containers/Buys"));
const Buy = lazy(() => import("./containers/Buy"));
const BuyCreatePage = lazy(() => import("./containers/Buy/BuyCreatePage"));
const ReportLogistics = lazy(() => import("./containers/Report/logistics"));
const ReportLogistic = lazy(() => import("./containers/Report/logistic"));
const ReportLogisticCreatePage = lazy(() => import("./containers/Report/logistic/createPage"));
const ReportOffices = lazy(() => import("./containers/Report/offices"));
const ReportOffice = lazy(() => import("./containers/Report/office"));
const ReportOfficeCreatePage = lazy(() => import("./containers/Report/office/createPage"));
const ReportVacations = lazy(() => import("./containers/Report/vacations"));
const ReportVacation = lazy(() => import("./containers/Report/vacation"));
const ReportVacationCreatePage = lazy(() => import("./containers/Report/vacation/createPage"));
const Prices = lazy(() => import("./containers/Prices"));
const Price = lazy(() => import("./containers/Price"));
const Discounts = lazy(() => import("./containers/Discounts"));
const Discount = lazy(() => import("./containers/Discount"));
const Refs = lazy(() => import("./containers/Refs"));
const Analysis = lazy(() => import("./containers/Analysis"));
const AnalysisInfo = lazy(() => import("./containers/AnalysisInfo"));
const BackOrders = lazy(() => import("./containers/BackOrders"));
const BackOrder = lazy(() => import("./containers/BackOrder"));
const BackOrderCreatePage = lazy(() => import("./containers/BackOrder/BackOrderCreatePage"));
const Wikis = lazy(() => import("./containers/Wikis"));
const Wiki = lazy(() => import("./containers/Wiki"));
const WikiCreate = lazy(() => import("./containers/Wiki/create"));
const Translate = lazy(() => import("./containers/Tools/Translate"));
const InvoiceDelivery = lazy(() => import("./containers/Invoice/InvoiceDelivery"));
const Stocks = lazy(() => import("./containers/Stocks"));
const StockCreatePage = lazy(() => import("./containers/Stocks/StockCreatePage"));
const Stock = lazy(() => import("./containers/Stock"));
const Tors = lazy(() => import("./containers/Tors"));
const Tor = lazy(() => import("./containers/Tor"));
const Tariffnumbers = lazy(() => import("./containers/Tariffnumbers"));
const ReportWorkTime = lazy(() => import("./containers/Report/WorkTime"));
// const PdfExtracts = lazy(() => import("./containers/PdfExtracts"));
// const PdfExtract = lazy(() => import("./containers/PdfExtract"));
class App extends Component {

    componentWillMount() {

        // window.live = 'http://apioffice.com/'; // Localhost
        // window.live = 'https://demo.api.aoioffice.com/'; // Demo
        window.live = 'https://api.office.aoidata.com/'; // Live

        window.auth = window.live + 'api/auth';
        window.api = window.live + 'api/office/';

        window.history_update_items = [
            {
                children: <>
                    <h4>2024-11-12</h4>
                    <ul>
                        <li>
                            订单-详情页-订单邮件: 增加复制按钮。（可以一键复制内容，粘贴到outlook邮件里）
                        </li>
                        <li>
                            订单-详情页-产品: 仓储同事现在可以设置产品塞货账单。
                        </li>
                        <li>
                            箱单-托盘: 现在支持在详情页导入Excel，通过Excel快速设置产品所在托盘与箱号。
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-11-04</h4>
                    <ul>
                        <li>
                            订单-详情页-产品Excel导入: D列合同价如果不需要导入，导入前删除D列即可。导入选项-合同币种，改为了非必须，默认是空。
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-10-25</h4>
                    <ul>
                        <li>
                            订单-详情页: 关联订单,手动关联超过1个时,该订单将自动变更状态为主订单。取消关联所有订单后，会自动取消主订单状态。
                        </li>
                        <li>
                            订单-详情页: 订单邮件按钮，主订单里的产品#序列号列,现在会从1开始按顺序显示。
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-10-15</h4>
                    <ul>
                        <li>
                            托盘管理-详情页: 增加搜索功能，可输入产品型号或订单号，快速查看所在托盘和箱号。
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-10-14</h4>
                    <ul>
                        <li>
                            订单详情页-产品: 增加[计划塞货]勾选项(表示需要塞货，但塞货的账单还没出现),订单-订单到货列表增加计划塞货过滤选项。Wiki:
                            <a href={'/wiki/31'} target='_blank'>{'<'}功能说明{'>'}</a>
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-09-30</h4>
                    <ul>
                        <li>
                            订单详情页-产品: 编辑产品到货/到货数量时，会自动计算到货情况到留言编辑框：PnDA/Pn(本次到货数)DA。Wiki:
                            <a href={'/wiki/30'} target='_blank'>{'<'}功能说明{'>'}</a>
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-09-26</h4>
                    <ul>
                        <li>
                            <Space direction="vertical">
                                <span>订单-详情页: </span>
                                <span>所有产品<Text type="success">全部到货</Text>后，<Text strong>订单主体状态</Text>自动变更：</span>
                                <Text keyboard><span style={{ color: '#000' }}>“订单到货状态” {'==>'} “全部到货”</span></Text>
                                <Text keyboard><span style={{ color: '#000' }}>“订单状态” {'==>'} “货到请付款”</span></Text>
                                <Text underline>注意，订单状态是【订单仍未下单、等待订单确认、PR需要预付、PR预付完成、确认收到等货】状态之一时才会执行变更。</Text>
                                <Divider />
                                <span>所有产品全部到货情况下，某个产品的状态更改为<Text type="warning">部分到货</Text>后，订单主体状态自动变更：</span>
                                <Text keyboard><span style={{ color: '#000' }}>“订单到货状态” {'==>'} “部分到货”</span></Text>
                                <Text keyboard><span style={{ color: '#000' }}>“订单状态” {'==>'} “确认收到等货”</span></Text>
                                <Text underline>注意，订单状态是【货到请付款】状态时才会执行变更。</Text>
                            </Space>
                        </li>
                        <li>
                            产品编辑: 到货数量、重量，新增快捷加/减操作，避免增加和减少要手动计算。Wiki:
                            <a href={'/wiki/29'} target='_blank'>{'<'}功能说明{'>'}</a>
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-09-24</h4>
                    <ul>
                        <li>
                            订单-库存管理，table底部增加库存总结，筛选项增加库存位置筛选。
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-09-23</h4>
                    <ul>
                        <li>
                            订单-产品Excel导入功能，新增D列合同单价导入项。Wiki:
                            <a href={'/wiki/26'} target='_blank'>{'<'}功能说明{'>'}</a>
                        </li>
                        <li>
                            运营-邮件AI翻译，增加提示词选项{'<'}修正德语语法和单词{'>'}。
                        </li>
                        <li>
                            顶部菜单栏增加更新日志按钮。Wiki:
                            <a href={'/wiki/27'} target='_blank'>{'<'}功能说明{'>'}</a>
                        </li>
                    </ul>
                </>,
            },
            {
                children: <>
                    <h4>2024-09-19</h4>
                    <ul>
                        <li>
                            订单详情页的图片，新增批量勾选下载原图功能。Wiki:
                            <a href={'/wiki/28'} target='_blank'>{'<'}功能说明{'>'}</a>
                        </li>
                    </ul>
                </>,
            },
        ];
    }

    requireAuth = (history) => {
        const cookies = new Cookies();
        var token = cookies.get('token');
        if (!token) {
            history.push('/login');
        }
    }

    render() {
        const history = createBrowserHistory();
        return (
            <>
                <CookiesProvider>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/" component={(props) => <Home {...props} />} onEnter={this.requireAuth(history)} />
                            <Route path="/orders" component={(props) => <Orders {...props} />} />
                            <Route path="/freights" component={(props) => <Freights {...props} />} />
                            <Route path="/transfers" component={(props) => <Transfers {...props} />} />
                            <Route path="/freight/create" component={(props) => <FreightCreate {...props} />} />
                            <Route path="/transfer/create" component={(props) => <TransferCreate {...props} />} />
                            <Route path="/transfer/:id" component={(props) => <Transfer {...props} />} />
                            <Route path="/freight/:id" component={(props) => <Freight {...props} />} />
                            <Route path="/order/create" component={(props) => <OrderCreatePage {...props} />} />
                            <Route path="/order/stock/create" component={(props) => <StockCreatePage {...props} />} />
                            <Route path="/order/stock/:id" component={(props) => <Stock {...props} />} />
                            <Route path="/order/stocks" component={(props) => <Stocks {...props} />} />
                            <Route path="/order/:id" component={(props) => <Order {...props} />} />
                            <Route path="/storage/order/:id" component={(props) => <StorageOrder {...props} />} />
                            <Route path="/storage" component={(props) => <Storage {...props} />} />
                            <Route path="/inventory" component={(props) => <Inventory {...props} />} />
                            <Route path="/delivery" component={(props) => <Delivery {...props} />} />
                            <Route path="/StorageOverview" component={(props) => <StorageOverview {...props} />} />
                            <Route path="/payments" component={(props) => <Payments {...props} />} />
                            <Route path="/waybills" component={(props) => <Waybills {...props} />} />
                            <Route path="/todolist" component={(props) => <Todolist {...props} />} />
                            <Route path="/waybill/create" component={(props) => <WaybillCreate {...props} />} />
                            <Route path="/sticker/create" component={(props) => <WaybillStickerCreate {...props} />} />
                            <Route path="/waybill/:id" component={(props) => <Waybill {...props} />} />
                            <Route path="/payment/create" component={(props) => <PaymentCreate {...props} />} />
                            <Route path="/paymentProformas" component={(props) => <PaymentProformas {...props} />} />
                            <Route path="/payment/:id" component={(props) => <Payment {...props} />} />
                            <Route path="/hscodes" component={(props) => <Hscodes {...props} />} />
                            <Route path="/hscode/create" component={(props) => <HscodeCreate {...props} />} />
                            <Route path="/hscode/:id" component={(props) => <Hscode {...props} />} />
                            <Route path="/incomes" component={(props) => <Incomes {...props} />} />
                            <Route path="/income/create" component={(props) => <IncomeCreate {...props} />} />
                            <Route path="/income/:id" component={(props) => <Income {...props} />} />
                            <Route path="/ztns" component={(props) => <Ztns {...props} />} />
                            <Route path="/ztn/create" component={(props) => <ZtnCreate {...props} />} />
                            <Route path="/ztn/:id" component={(props) => <Ztn {...props} />} />
                            <Route path="/tariffnumbers" component={(props) => <Tariffnumbers {...props} />} />
                            <Route path="/brands" component={(props) => <Brands {...props} />} />
                            <Route path="/brand/create" component={(props) => <BrandCreatePage {...props} />} />
                            <Route path="/brand/:id" component={(props) => <Brand {...props} />} />
                            <Route path="/login" component={(props) => <Login {...props} />} />
                            <Route path="/logout" component={(props) => <Logout {...props} />} />
                            <Route path="/resources" component={(props) => <Resources {...props} />} />
                            <Route path="/resource/create" component={(props) => <ResourceCreatePage {...props} />} />
                            <Route path="/resource/:id" component={(props) => <Resource {...props} />} />
                            <Route path="/clients" component={(props) => <Clients {...props} />} />
                            <Route path="/client/create" component={(props) => <ClientCreatePage {...props} />} />
                            <Route path="/client/:id" component={(props) => <Client {...props} />} />
                            <Route path="/buys" component={(props) => <Buys {...props} />} />
                            <Route path="/buy/create" component={(props) => <BuyCreatePage {...props} />} />
                            <Route path="/buy/:id" component={(props) => <Buy {...props} />} />
                            <Route path="/invoices" component={(props) => <Invoices {...props} />} />
                            <Route path="/invoice/create" component={(props) => <InvoiceCreatePage {...props} />} />
                            <Route path="/invoice/delivery" component={(props) => <InvoiceDelivery {...props} />} />
                            <Route path="/invoice/:id" component={(props) => <Invoice {...props} />} />
                            <Route path="/report/logistics" component={(props) => <ReportLogistics {...props} />} />
                            <Route path="/report/logistic/create" component={(props) => <ReportLogisticCreatePage {...props} />} />
                            <Route path="/report/logistic/:id" component={(props) => <ReportLogistic {...props} />} />
                            <Route path="/report/offices" component={(props) => <ReportOffices {...props} />} />
                            <Route path="/report/office/create" component={(props) => <ReportOfficeCreatePage {...props} />} />
                            <Route path="/report/office/:id" component={(props) => <ReportOffice {...props} />} />
                            <Route path="/report/vacations" component={(props) => <ReportVacations {...props} />} />
                            <Route path="/report/worktime" component={(props) => <ReportWorkTime {...props} />} />
                            <Route path="/report/vacation/create" component={(props) => <ReportVacationCreatePage {...props} />} />
                            <Route path="/report/vacation/:id" component={(props) => <ReportVacation {...props} />} />
                            <Route path="/categories" component={(props) => <Categories {...props} />} />
                            <Route path="/category/create" component={(props) => <CategoryCreatePage {...props} />} />
                            <Route path="/category/:id" component={(props) => <Category {...props} />} />
                            <Route path="/contract/create" component={(props) => <ContractCreatePage {...props} />} />
                            <Route path="/contract/analysis" component={(props) => <ContractAnalysisPage {...props} />} />
                            <Route path="/contracts" component={(props) => <Contracts {...props} />} />
                            <Route path="/contract/:id" component={(props) => <Contract {...props} />} />
                            <Route path="/inquiry/:id" component={(props) => <Inquiry {...props} />} />
                            <Route path="/inquiries" component={(props) => <Inquiries {...props} />} />
                            <Route path="/members" component={(props) => <Members {...props} />} />
                            <Route path="/user" component={(props) => <User {...props} />} />
                            <Route path="/admin/users" component={(props) => <BlockUsers {...props} />} />
                            <Route path="/admin/permissions" component={(props) => <Permissions {...props} />} />
                            <Route path="/prices" component={(props) => <Prices {...props} />} />
                            <Route path="/price/:id" component={(props) => <Price {...props} />} />
                            <Route path="/discounts" component={(props) => <Discounts {...props} />} />
                            <Route path="/discount/:id" component={(props) => <Discount {...props} />} />
                            <Route path="/refs" component={(props) => <Refs {...props} />} />
                            <Route path="/analysis/:id" component={(props) => <AnalysisInfo {...props} />} />
                            <Route path="/analysis" component={(props) => <Analysis {...props} />} />
                            <Route path="/back/orders" component={(props) => <BackOrders {...props} />} />
                            <Route path="/back/order/create" component={(props) => <BackOrderCreatePage {...props} />} />
                            <Route path="/back/order/:id" component={(props) => <BackOrder {...props} />} />
                            <Route path="/wikis" component={(props) => <Wikis {...props} />} />
                            <Route path="/wiki/create" component={(props) => <WikiCreate {...props} />} />
                            <Route path="/wiki/:id" component={(props) => <Wiki {...props} />} />
                            <Route path="/tools/translate" component={(props) => <Translate {...props} />} />
                            <Route path="/tors" component={(props) => <Tors {...props} />} />
                            <Route path="/tor/:id" component={(props) => <Tor {...props} />} />
                            {/* <Route path="/pdf_extracts" component={(props) => <PdfExtracts {...props} />} />
                            <Route path="/pdf_extract/:id" component={(props) => <PdfExtract {...props} />} /> */}
                        </Switch>
                    </Router>
                </CookiesProvider>
                <MessageBar />
                <FloatButton.BackTop className='backTopStyle' shape='square' icon={<UpOutlined />} />
            </>
        )

    }
}

export default App;
