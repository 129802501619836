export default function reducer(state={
		relations: [],
		message: [],
		fetching: false,
		fetched: false,
		error: null
	}, action) {

	switch (action.type) {
		case "CREATE_RELATION": {
			return {...state, fetching: true}
		}
		case "CREATE_RELATION_REJECTED": {
			return {...state, fetching: false, error: action.payload.response.data.error}
		}
		case "CREATE_RELATION_FULFILLED": {
			return {...state, fetching: false, fetched: true, error: null, relations: action.payload.relations, message: action.payload.message}
		}
		case "UPDATE_RELATION": {
			return {...state, fetching: true}
		}
		case "UPDATE_RELATION_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_RELATION_FULFILLED": {
			return {...state, fetching: false, fetched: true, relations: action.payload.relations, message: action.payload.message}
		}
		case "DELETE_RELATION": {
			return {...state, fetching: true}
		}
		case "DELETE_RELATION_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_RELATION_FULFILLED": {
			return {...state, fetching: false, fetched: true, relations: action.payload.relations, message: action.payload.message}
		}
		default: {
        	break;
		}
	}

	return state
}